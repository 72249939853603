import React from "react";
import { WhyChooseAKInvestment } from "./WhyChooseUs";
import { BuyInsurancePage } from "./BuyInsurance";
import { OurInsurancePage } from "./OurInsurance";
import { ManageYourInsurancePage } from "./ManageYourInsurance";
import { TypesOfInsurancePage } from "./TypesOfInsurance";
import { OurPartnersPage } from "./OurPartners";
import { TestimonialPage } from "./Testimonial";
import { OurAchievementPage } from "./OurAchievement";
import { FreeConsultancyPage } from "./FreeConsultancy";

const HomePage = () => {
  return (
    <div className="flex flex-col gap-100 mt-[14px]">
      <div className="sm:px-[30px] px-[16px]">
        <BuyInsurancePage />
      </div>
      <WhyChooseAKInvestment />
      <OurInsurancePage />
      <div className="px-[30px]">
        <ManageYourInsurancePage />
      </div>
      <TypesOfInsurancePage />
      {/* <OurPartnersPage /> */}
      <TestimonialPage />
      <OurAchievementPage />
    </div>
  );
};

export { HomePage };
