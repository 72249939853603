import React from "react";
import TextWithBorders from "../../components/componentHeading";
import InsuranceCard from "../../components/InsuranceDetails";

import life01 from "../../assets/life01.png";
import life02 from "../../assets/life02.png";
import life03 from "../../assets/life03.png";
import life04 from "../../assets/Miscellaneous01.png";
import life05 from "../../assets/Miscellaneous02.png";
import life06 from "../../assets/Miscellaneous03.png";

const insuranceData = [
  {
    id: "01",
    title: "Term Insurance",
    description:
      "Term insurance provides financial protection for the policyholder's family in the event of death during the policy term. The policy is cost-effective, with premiums based on age, health, and coverage amount. It ensures a payout to beneficiaries if the insured passes away within the term.If the policyholder survives the term, no amount is paid, though some plans offer additional benefits. Term insurance can be extended or renewed up to a certain age, with some policies offering options like riders for additional coverage",
    imgFirst: true,
    image: life01,
  },
  {
    id: "02",
    title: "Pension Plans",
    description:
      "Pension plans are designed to provide income after retirement, with options such as deferred and immediate annuities. Deferred annuities begin after a set period, while immediate annuities start payments right away. These plans offer flexibility in payout options and can include spouse benefits, along with tax advantages.When choosing a pension plan, it's essential to consider factors like payout duration, flexibility, and the ability to support long-term retirement goals. This ensures a steady income stream during retirement.",
    imgFirst: false,
    image: life02,
  },
  {
    id: "03",
    title: "Child investment plans",
    description:
      "Child investment plans are specialized insurance policies designed to secure a child’s future by covering educational expenses and other significant financial needs. These plans are structured to accumulate funds over time, providing a financial corpus when the child reaches maturity. In case of the parent's death, these plans typically offer a premium waiver, ensuring the policy continues without further contributions from the policyholder.These plans offer a combination of life insurance coverage and investment benefits, helping to build funds for the child’s future goals. The flexible nature of child plans ensures that they cater to various financial needs, such as education and marriage.",
    imgFirst: true,
    image: life03,
  },

  {
    id: "04",
    title: "Unit Linked Insurance Plans (ULIPs)",
    description:
      "Unit Linked Insurance Plans (ULIPs) offer a dual benefit of life insurance coverage and investment opportunities. Premiums are invested in different funds, such as equity, debt, or balanced funds, depending on the policyholder's risk appetite. The plan's value grows with the market's performance, and upon death or maturity, the higher of the sum assured or fund value is paid.ULIPs offer features like flexible premium payments, a 5-year lock-in period, and partial withdrawals after the lock-in. Additional premiums can be invested, and some plans offer guaranteed additions.",
    imgFirst: true,
    image: life06,
  },
  {
    id: "05",
    title: "Money-Back Policy",
    description:
      "A money-back policy offers the benefit of periodic payouts during the policy term if the policyholder survives. This ensures financial stability through regular income, with the sum assured paid to the nominee in case of the policyholder's untimely death.These plans also provide tax benefits, and some policies may include bonuses. The coverage can be extended with additional riders, enhancing the overall protection. This makes them ideal for individuals seeking guaranteed returns, regular payouts, and comprehensive life insurance coverage.",
    imgFirst: true,
    image: life04,
  },
  {
    id: "06",
    title: "Savings",
    description:
      "An endowment policy combines life insurance with a savings component, offering guaranteed returns either on death or at the policy's maturity. The policyholder can receive bonuses or loyalty additions, which enhance the benefit. It is ideal for those planning long-term financial goals, such as retirement or children’s education.These policies are available in various forms, including traditional, low-cost, and unit-linked options, offering flexibility. Premiums are tax-deductible, and the payout benefits are tax-exempt.",
    imgFirst: false,
    image: life05,
  },

];

const OurLifeInsurancePage = () => {
  return (
    <div className="flex flex-col gap-[50px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Our Life Insurance"
        description="Secure Your Future with Trusted Life Insurance Solutions.
Protect What Matters Most, Today and Tomorrow."
      />
      <div className="flex flex-col gap-[100px]">
        {insuranceData.map((insurance) => (
          <InsuranceCard
            key={insurance.id}
            title={insurance.title}
            description={insurance.description}
            image={insurance.image}
            imgFirst={insurance.imgFirst}
            id={insurance.id}
          />
        ))}
      </div>
    </div>
  );
};

export { OurLifeInsurancePage };
