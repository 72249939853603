import React from "react";
import styled from "styled-components";
import { Loader } from "./Loader";

export const ButtonComp = ({
  textcard,
  handleAction,
  isLoading,
  color,
  background,
  width,
  height,
  borderRadius,
  disabled,
}) => {
  return handleAction ? (
    <PublicButtonWrapper
      onClick={!disabled ? handleAction : undefined}
      color={color}
      background={background}
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      {isLoading ? (
        <Loader size={24} color={"#688540"} />
      ) : (
        <button type="submit" disabled={disabled || isLoading}>
          {textcard}
        </button>
      )}
    </PublicButtonWrapper>
  ) : (
    <PublicButtonWrapper
      color={color}
      background={background}
      width={width}
      height={height}
      borderRadius={borderRadius}
    >
      {isLoading ? (
        <Loader size={24} color={"#688540"} />
      ) : (
        <button type="submit" disabled={disabled || isLoading}>
          {textcard}
        </button>
      )}
    </PublicButtonWrapper>
  );
};

const PublicButtonWrapper = styled.div`
  width: ${({ width }) => (width ? width : "100%")};

  button {
    width: ${({ width }) => (width ? width : "100%")};
    height: ${({ height }) => (height ? height : "auto")};
    padding: 15px;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : "8px"};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${({ background }) => (background ? background : "#3d97f2")};
    color: ${({ color }) => (color ? color : "#fff")};
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: none;
    letter-spacing: -0.01em;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;

    &:hover {
      background-color: ${({ background }) =>
        background ? darkenColor(background, 10) : "#2c87d9"};
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.16);
      transform: translateY(-2px);
    }

    &:active {
      background-color: ${({ background }) =>
        background ? darkenColor(background, 20) : "#256ba3"};
      transform: translateY(0);
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #696969;
      opacity: 0.5;
      box-shadow: none;
      transform: none;
    }
  }

  div {
    width: 100%;
    padding: 15px;
    background: transparent;
    border-radius: 8px;
    text-align: center;
    color: #fff;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border: none;
    letter-spacing: -0.01em;
    cursor: pointer;
  }
`;

// Helper function to darken the background color
const darkenColor = (color, percent) => {
  const amount = Math.round(2.55 * percent);
  return color
    .replace(/^#/, "")
    .replace(/../g, (col) =>
      ("0" + Math.max(0, Math.min(255, parseInt(col, 16) - amount)).toString(16))
        .slice(-2)
    );
};
