import React from "react";

const TextWithBorders = ({ title, subtitle, description }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex items-center gap-[6px]">
        <div className="bg-oliveGreen h-[1px] w-[50px]"></div>
        <p className="text-center text-[24px] font-normal text-oliveGreen">
          {title}
        </p>
        <div className="bg-oliveGreen h-[1px] w-[50px]"></div>
      </div>
      <p className="text-center text-[25px] sm:text-48 font-bold text-darkGray mb-[16px]">
        {subtitle}
      </p>
      <p className="text-center text-[14px] font-normal text-mediumGray lg:w-[635px] w-full">
        {description}
      </p>
    </div>
  );
};

export default TextWithBorders;
