import React from 'react'
import CoverPhoto from '../../components/CoverPhoto';
import LifePhoto from "../../assets/life.jpg"
import { AboutLifeInsurancePage } from './AboutLifeInsurance';
import { OurLifeInsurancePage } from './OurLifeInsurance';
import { TestimonialPage } from '../Testimonial';
import { useOutletContext } from 'react-router-dom';

function Life() {
    const { contactUsRef } = useOutletContext();

    return (
        <div className='w-full h-full flex flex-col gap-100' >
            <CoverPhoto title="Life Insurance" image={LifePhoto} />
            <AboutLifeInsurancePage contactUsRef={contactUsRef}/>
            <OurLifeInsurancePage/>
            <TestimonialPage/>
        </div>
    )
}

export { Life };