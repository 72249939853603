import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import TextWithBorders from "../components/componentHeading";
import {
  CarInsuranceIcon,
  HealthInsuranceIcon,
  LifeInsuranceIcon,
  MiscellaneousInsuranceIcon,
} from "../utils/SVG";
import { useNavigate } from "react-router-dom";

const insuranceData = [
  {
    icon: <CarInsuranceIcon />,
    title: "Motor",
    description:
      "A Car Insurance is a contract between the car owner and a general insurance company...",
    gridArea: "motor",
    link: "/motor",
  },
  {
    icon: <LifeInsuranceIcon />,
    title: "Life",
    description:
      "Life insurance plans are characterized by the fact that they pay a benefit on death of the insured...",
    gridArea: "life",
    link: "/life",
  },
  {
    icon: <MiscellaneousInsuranceIcon />,
    title: "Miscellaneous",
    description:
      "Life is uncertain and the risk of untimely death is quite relevant. If the breadwinner of the family dies suddenly...",
    gridArea: "misc",
    link: "/miscellaneous",
  },
  {
    icon: <HealthInsuranceIcon />,
    title: "Health",
    description:
      "Health insurance, also called Mediclaim, is a way to pay for advanced medical treatments that typically require you to be in hospital overnight.",
    gridArea: "health",
    link: "/health",
  },
];

const OurInsurancePage = () => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    threshold: 0.6, // Trigger animation when 20% of the section is visible
    triggerOnce: true, // Ensure animation runs only once
  });

  return (
    <div ref={ref} className="lg:px-[120px] px-[30px] flex flex-col gap-[32px]">
      <TextWithBorders
        title="Our Insurance"
        subtitle="Get Insurance You Want"
        description="Find the Perfect Policy for Your Peace of Mind.
Customized Insurance Solutions, Just for You."
      />

      <div
        className="md:grid md:grid-cols-3 flex flex-col md:gap-[49px] gap-[32px] mt-8"
        style={{
          gridTemplateAreas: `
            "motor life misc"
            "motor health health"
          `,
        }}
      >
        {insuranceData?.map(
          ({ icon, title, description, gridArea, link }, index) => (
            <motion.div
              key={title}
              className="bg-gradient-to-r from-gray-100 border border-[#DADADA] to-white rounded-[12px] p-[50px] justify-between flex flex-col items-start gap-[40px] cursor-pointer hover:shadow-lg hover:bg-custom-gradient group"
              style={{ gridArea }}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              whileHover={{ scale: 1.05 }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.8, delay: index * 0.2 },
                },
              }}
              onClick={() => navigate(link)}
            >
              {icon}
              <div>
                <h3 className="text-xl font-semibold text-black group-hover:text-white">
                  {title}
                </h3>
                <p className="text-black text-sm mb-[24px] group-hover:text-white">
                  {description}
                </p>
                <a
                  href={link}
                  className="text-oliveGreen font-medium flex items-center gap-2 hover:underline group-hover:text-white"
                >
                  Know More <span>→</span>
                </a>
              </div>
            </motion.div>
          )
        )}
      </div>
    </div>
  );
};

export { OurInsurancePage };
