import React, { useState, useEffect } from "react";
import TextWithBorders from "../components/componentHeading";
import { Carousel } from "antd";
import motor2Img from "../assets/motor2Img.jpg";
import { useNavigate } from "react-router-dom";

const OurAchievementPage = () => {
  const navigate = useNavigate();

  // const reviews = [
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  //   { image: motor2Img },
  // ];

  const reviews = [
    { image: "https://images.pexels.com/photos/7005687/pexels-photo-7005687.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { image: "https://images.pexels.com/photos/2040276/pexels-photo-2040276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { image: "https://images.pexels.com/photos/6120394/pexels-photo-6120394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { image: "https://images.pexels.com/photos/7005686/pexels-photo-7005686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { image: "https://images.pexels.com/photos/7267587/pexels-photo-7267587.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
    { image: "https://images.pexels.com/photos/8112198/pexels-photo-8112198.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" },
  ];


  const chunkReviews = (reviews, size) => {
    const chunks = [];
    for (let i = 0; i < reviews.length; i += size) {
      chunks.push(reviews.slice(i, i + size));
    }
    return chunks;
  };

  const [chunkSize, setChunkSize] = useState(3);

  useEffect(() => {
    const updateChunkSize = () => {
      const isMediumScreen = window.innerWidth >= 768;
      setChunkSize(isMediumScreen ? 3 : 1);
    };

    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);

    return () => {
      window.removeEventListener("resize", updateChunkSize);
    };
  }, []);

  const reviewChunks = chunkReviews(reviews, chunkSize);

  return (
    <div className="flex flex-col gap-[40px] px-[70px] max-800px:px-[30px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Our Achievement"
        description="Celebrating Milestones of Excellence.
Proven Success in Delivering Trusted Insurance Solutions."
      />

      <Carousel
        prefixCls="review-carousel"
        autoplay
        dots={true}
        dotPosition={"bottom"}
      >
        {reviewChunks.map((chunk, index) => (
          <div key={index} onClick={() => navigate("/achievement")} className="flex cursor-pointer gap-[30px]">
            {chunk.map((review, index) => (
              <div key={index} className="flex flex-col gap-[34px] bg-white">
                <div className="flex justify-center items-center">
                  <img
                    src={review.image}
                    alt="motor2"
                    className="w-full h-auto rounded-[20px]"
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { OurAchievementPage };
