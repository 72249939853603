import React from "react";
import aboutImg from "../../assets/aboutImg.png";

const AboutUsPage = () => {
  return (
    <div className="flex gap-[50px] lg:mx-[130px] mx-[20px] md:flex-row flex-col">
      <div className="flex flex-col justify-center">
        <div className="flex gap-[6px] items-center">
          <p className="text-oliveGreen text-24 m-0 font-normal">About Us</p>
          <div className="bg-oliveGreen w-[99px] h-[1px]"></div>
        </div>
        <p className="text-black text-[25px] sm:text-[48px] m-0 font-semibold leading-[37px] sm:leading-[57px]">
          Proud Moments That Define Us
        </p>
        <p className="text-black text-[14px] mt-[24px] font-normal leading-[21px]">
          At the heart of our journey lies a steadfast commitment to innovation, customer-centricity, and unwavering values. Dr. Amit Kumar, our guiding force, leads with a vision of creating positive transformations and delivering on promises.
        </p>
        <p className="text-black text-[14px] mt-[12px] font-normal leading-[21px]">
          Every interaction with our clients and partners reflects our dedication to excellence and trust. We embrace change as an opportunity to grow, continuously striving to exceed expectations and inspire meaningful progress.
        </p>
        <p className="text-black text-[14px] mt-[12px] font-normal leading-[21px]">
          Your success is our pride. Together, we transform challenges into opportunities, ensuring a future defined by value, commitment, and collaboration.
        </p>
      </div>

      <img
        src={aboutImg}
        alt=""
        className="w-[500px] h-[629px] object-cover rounded-[20px] 320px-767px:w-full 320px-767px:h-[400px] 768px-1022px:w-[300px] 768px-1022px:h-[300px]"
      />
    </div>
  );
};

export { AboutUsPage };
