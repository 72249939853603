import React from "react";
import TextWithBorders from "../../components/componentHeading";
import InsuranceCard from "../../components/InsuranceDetails";

import Miscellaneous01 from "../../assets/Miscellaneous01.jpg";
import Miscellaneous02 from "../../assets/Miscellaneous02.jpg";
import Miscellaneous03 from "../../assets/Miscellaneous03.png";
import Miscellaneous04 from "../../assets/Miscellaneous04.webp";
import Miscellaneous05 from "../../assets/Miscellaneous05.jpg";
import Miscellaneous06 from "../../assets/Miscellaneous06.jpg";
import Miscellaneous07 from "../../assets/Miscellaneous07.jpg";
import Miscellaneous08 from "../../assets/Miscellaneous08.webp";

const insuranceData = [
  {
    id: "01",
    title: "Fire Insurance",
    description:
      "Fire insurance provides financial protection against damages or losses caused by fire and related perils such as lightning or explosion. This policy covers the cost of repairing, rebuilding, or replacing insured property, including buildings, machinery, and contents. Fire insurance can also be extended to cover additional risks such as smoke damage or water used in firefighting. It ensures that individuals and businesses can recover from the financial impact of fire incidents, offering peace of mind and stability.",
    imgFirst: true,
    image: Miscellaneous01,
  },
  {
    id: "02",
    title: "Marine Insurance",
    description:
      "Marine insurance provides coverage for ships, cargo, terminals, and other means of transportation involved in the transfer of goods by sea. It protects against losses or damages caused by perils such as storms, collisions, piracy, or accidents during transit. Marine insurance is essential for importers, exporters, and businesses to ensure the safe transport of goods and mitigate financial risks associated with maritime operations.",
    imgFirst: false,
    image: Miscellaneous02,
  },
  {
    id: "03",
    title: "Cargo Insurance",
    description:
      "Cargo insurance is a specialized policy that covers goods while they are in transit, whether by land, sea, or air. It provides financial protection against damages, theft, or loss of cargo due to unforeseen events during transportation. This insurance is crucial for businesses to safeguard their inventory and ensure continuity in supply chains.",
    imgFirst: true,
    image: Miscellaneous03,
  },
  {
    id: "04",
    title: "Property Insurance",
    description:
      "Property insurance offers financial protection against damages or losses to physical assets such as buildings, equipment, and personal belongings. It typically covers risks like fire, theft, vandalism, and natural disasters such as floods or earthquakes. Property insurance is essential for homeowners and businesses to secure their assets and mitigate financial losses.",
    imgFirst: false,
    image: Miscellaneous04,
  },
  {
    id: "05",
    title: "Business Insurance",
    description:
      "Business insurance is a comprehensive policy designed to protect companies from financial losses due to unforeseen events. It includes coverage for property damage, liability claims, employee-related risks, and business interruptions. Tailored to specific industries, business insurance ensures financial stability and continuity in case of unexpected challenges.",
    imgFirst: true,
    image: Miscellaneous05,
  },
  {
    id: "06",
    title: "Professional Liability Insurance",
    description:
      "Professional liability insurance, also known as errors and omissions (E&O) insurance, protects professionals and businesses from claims of negligence, errors, or omissions in the services they provide. This policy covers legal defense costs and settlements, ensuring that professionals like doctors, lawyers, and consultants can perform their work without the fear of financial ruin from lawsuits.",
    imgFirst: false,
    image: Miscellaneous06,
  },
  {
    id: "07",
    title: "Home Insurance",
    description:
      "Home insurance provides financial protection for homeowners against damages or losses to their house and personal belongings due to risks like fire, theft, natural disasters, or vandalism. It typically includes coverage for the structure, contents, and liability for accidents occurring on the property. Home insurance ensures peace of mind by safeguarding your most valuable asset.",
    imgFirst: true,
    image: Miscellaneous07,
  },
  {
    id: "08",
    title: "Commercial Insurance",
    description:
      "Commercial insurance is a broad category of coverage designed for businesses, protecting them against risks such as property damage, liability claims, employee injuries, and business interruptions. It can be tailored to meet the unique needs of various industries, ensuring financial security and operational continuity for businesses of all sizes.",
    imgFirst: false,
    image: Miscellaneous08,
  },
];


const OurMiscellaneousInsurancePage = () => {
  return (
    <div className="flex flex-col gap-[50px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Our Miscellaneous Insurance"
        description="All-Round Protection, Just for You.
Explore Our Range of Miscellaneous Insurance Options"
      />
      <div className="flex flex-col gap-[100px]">
        {insuranceData.map((insurance) => (
          <InsuranceCard
            key={insurance.id}
            title={insurance.title}
            description={insurance.description}
            image={insurance.image}
            imgFirst={insurance.imgFirst}
            id={insurance.id}
          />
        ))}
      </div>
    </div>
  );
};

export { OurMiscellaneousInsurancePage };
