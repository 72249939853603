import React from "react";
import CoverPhoto from "../../components/CoverPhoto";
import AboutPhoto from "../../assets/aboutus.jpg";
import { WhyChooseAKInvestment } from "../WhyChooseUs";
import { OurAchievementPage } from "../OurAchievement";
// import { HardworkingLeaderShipPage } from "./HardworkingLeaderShip";
import { AboutDirectorPage } from "./AboutDirector";
import { AboutUsPage } from "./AboutUs";

function About() {
  return (
    <div className="w-full h-full flex flex-col gap-100">
      <CoverPhoto title="About a.k. investments" image={AboutPhoto} />
      <AboutUsPage/>
      <WhyChooseAKInvestment />
      <AboutDirectorPage />
      {/* <HardworkingLeaderShipPage /> */}
      <OurAchievementPage />
    </div>
  );
}

export { About };
