import React from "react";
import lifeImg from "../../assets/lifeImg.png";

const AboutLifeInsurancePage = ({contactUsRef}) => {

  const scrollToContactUS = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className="flex gap-[50px] lg:mx-[130px] mx-[20px] md:flex-row flex-col">
      <div className="flex flex-col justify-center">
        <div className="flex gap-[6px] items-center">
          <p className="text-oliveGreen text-24 m-0 font-normal">Life Insurance</p>
          <div className="bg-oliveGreen w-[99px] h-[1px]"></div>
        </div>
        <p className="text-black text-[25px] sm:text-[48px] m-0 font-semibold leading-[37px] sm:leading-[57px]">
          Know More About Our Life Insurance
        </p>
        <p className="text-black text-[14px] mt-[24px] font-normal leading-[21px]">
          Life insurance policies offer various plans, including term insurance,
          pension plans, and child investment policies. Term insurance provides
          coverage for a set period, while pension plans secure retirement
          income. Child investment plans combine insurance with investment,
          ensuring financial support for a child’s future. These policies offer
          different benefits, such as premium waivers upon the policyholder's
          death and flexible payout structures. They help cover important life
          expenses like education, marriage, or retirement.
        </p>

        <button onClick={() => scrollToContactUS(contactUsRef)}  className="text-white bg-[#688540] w-[fit-content] h-[40px] mt-[40px] rounded-full py-2 px-4 text-xs font-semibold hover:bg-[#557032]">
          Free Insurance Consultancy
        </button>
      </div>

      <img
        src={lifeImg}
        alt=""
        className="w-[500px] h-[629px] object-cover rounded-[20px] 320px-767px:w-full 320px-767px:h-[400px] 768px-1022px:w-[300px] 768px-1022px:h-[300px]"
      />
    </div>
  );
};

export { AboutLifeInsurancePage };
