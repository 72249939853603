import React, { useEffect, useState } from "react";
import { QuotationIcon } from "../utils/SVG";
import { Avatar, Rate, Carousel } from "antd";
import { UserOutlined } from "@ant-design/icons";

const reviews = [
  {
    quote:
      "🌟 I had a fantastic experience with this company! They helped me find the perfect health insurance plan that fits my budget and covers everything I need. The team was professional, patient, and explained everything in detail. Highly recommended! 👍",
    name: "Sanjeev Kumar",
    location: "New Delhi, India",
    rating: 5,
  },
  {
    quote:
      "👏 A.K. Investments made getting health insurance incredibly simple and stress-free. Their team was knowledgeable and helped me choose a plan that perfectly suited my needs. I’m so glad I found them! 💼",
    name: "Jayant Kumar",
    location: "New Delhi, India",
    rating: 5,
  },
  {
    quote:
      "🚗 I recently purchased motor insurance through A.K. Investments, and I couldn’t be happier with the service. They explained all the options clearly and made the process smooth and efficient. Highly recommended! 🙌",
    name: "Raman Saini",
    location: "Punjab, India",
    rating: 4,
  },
  {
    quote:
      "❤️ Choosing life insurance felt overwhelming until I connected with this company. They guided me through the options and ensured I picked the right plan for my family's future. Their customer service is exceptional! 💡",
    name: "Anita Sharma",
    location: "Mumbai, India",
    rating: 4,
  },
  {
    quote:
      "💪 A.K. Investments is my go-to for all insurance needs! From life insurance to health coverage, they offer comprehensive solutions with excellent customer service. I trust them completely with my family’s future. 🌟",
    name: "Rajesh Verma",
    location: "Bangalore, India",
    rating: 5,
  },
  {
    quote:
      "✨ Dealing with A.K. Investments was an absolute pleasure. Their team went above and beyond to help me secure the right insurance policies for my car and health. Their commitment to customer satisfaction is truly commendable. 🙏",
    name: "Sunita Reddy",
    location: "Hyderabad, India",
    rating: 5,
  },
];


const chunkReviews = (reviews, size) => {
  const chunks = [];
  for (let i = 0; i < reviews.length; i += size) {
    chunks.push(reviews.slice(i, i + size));
  }
  return chunks;
};

const TestimonialPage = () => {
  const [chunkSize, setChunkSize] = useState(3);

  useEffect(() => {
    const updateChunkSize = () => {
      const isMediumScreen = window.innerWidth >= 768;
      setChunkSize(isMediumScreen ? 3 : 1);
    };

    updateChunkSize();
    window.addEventListener("resize", updateChunkSize);

    return () => {
      window.removeEventListener("resize", updateChunkSize);
    };
  }, []);

  const reviewChunks = chunkReviews(reviews, chunkSize);

  return (
    <div className="flex flex-col gap-[50px] lg:px-[80px] pb-[37px] px-[30px]">
      <div className="flex justify-between items-center md:flex-row flex-col md:gap-[0px] gap-[30px]">
        <div className="flex flex-col gap-[10px]">
          <div className="flex gap-[6px] items-center">
            <p className="text-oliveGreen text-24 m-0 font-normal">
              Testimonial
            </p>
            <div className="bg-oliveGreen w-[99px] h-[1px]"></div>
          </div>
          <p className="text-black text-[30px] lg:text-48 m-0 font-semibold md:w-[470px] w-full leading-[37px] sm:leading-[57px]">
            Don’t trust us our word trust our client
          </p>
        </div>

        <p className="text-mediumGray text-14 m-0 font-normal md:w-[376px] w-full leading-[21px]">
          Hear It Straight from Those Who Matter Most.
          Real Stories, Real Trust, Real Results.
        </p>
      </div>

      {/* Carousel for review cards */}
      <Carousel
        prefixCls="review-carousel"
        autoplay
        dots={true}
        dotPosition={"bottom"}
      >
        {reviewChunks.map((chunk, index) => (
          <div key={index} className="flex gap-[30px] ">
            {chunk.map((review, index) => (
              <div
                key={index}
                className="flex flex-col gap-[34px]  bg-white p-[20px] rounded-lg"
              >
                <div className="flex justify-between">
                  <QuotationIcon />
                  <Rate defaultValue={review?.rating} disabled={true} />
                </div>
                <p className="text-[16px] m-0 font-normal text-black">
                  {review?.quote}
                </p>

                <div className="flex gap-[21px] items-center mt-[6px]">
                  <Avatar size={64} icon={<UserOutlined />} />
                  <div>
                    <p className="text-[16px] m-0 font-semibold text-black">
                      {review?.name}
                    </p>
                    <p className="text-[16px] m-0 font-normal text-[#696969]">
                      {review?.location}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { TestimonialPage };
