import React from "react";
import TextWithBorders from "../../components/componentHeading";
import InsuranceCard from "../../components/InsuranceDetails";

import health01 from "../../assets/health01.png";
import health02 from "../../assets/health02.png";
import health03 from "../../assets/health03.png";
import health04 from "../../assets/health05.webp";
import health05 from "../../assets/life03.png";

const insuranceData = [
  {
    id: "01",
    title: "Family Health Insurance",
    description:
      "A **family health insurance plan** is a policy that provides health coverage for your entire family, including your spouse, children, dependent siblings, parents, and parents-in-law. With a single premium payment, the sum assured is shared among all covered members, typically extending coverage to up to six individuals. This plan simplifies the insurance process, ensuring that all family members are protected under one policy.  There are two main types of family health insurance plans: **individual family health insurance policies**, where each member is covered separately, and **family floater health insurance policies**, where the entire family is covered under a single policy. While individual policies tend to be more expensive due to separate premiums for each member, a family floater plan is generally more cost-effective and easier to manage, offering comprehensive coverage for all family members under one plan.",
    imgFirst: true,
    image: health03,
  },
  {
    id: "02",
    title: "Personal Accident Insurance",
    description:
      "Personal accident insurance is a type of coverage that provides financial protection in case of accidents, covering contingencies like injury, disability, or death. The policy offers a lump-sum payout to help cover medical costs, loss of income, or other expenses arising from the accident. This type of insurance ensures that individuals and their families are not burdened with financial stress during an already difficult time.There are two main types of personal accident insurance plans: individual personal accident plans, which cover one person, and group personal accident plans, which cover a group of individuals, such as employees, members of organizations, or clubs. Group plans are typically purchased by an organization to provide coverage for its members under a single policy. Both types ensure that those affected by accidents receive crucial financial support to recover and manage their day-to-day lives.",
    imgFirst: false,
    image: health02,
  },
  {
    id: "03",
    title: "Critical Illness Insurance",
    description:
      "Critical illness insurance provides financial protection against life-threatening health conditions such as cancer, kidney failure, stroke, paralysis, and organ transplants. These illnesses often require costly, long-term treatments that can strain finances.A critical illness policy offers a lump-sum payout upon diagnosis of any covered condition, regardless of actual medical expenses. This payout helps manage treatment costs, recovery expenses, and other financial needs, ensuring security and peace of mind during challenging times.",
    imgFirst: true,
    image: health01,
  },
  {
    id: "04",
    title: "Overseas Travel Medical Insurance",
    description:
      "Overseas travel medical insurance, also known as international health insurance, is a type of insurance that covers medical expenses and emergency evacuations while traveling abroad",
    imgFirst: true,
    cover: [
      {
        name: "•Medical expenses",
        des: "Covers medical and dental expenses, including hospital stays, doctor visits, and emergency medical evacuation."
      },
      {
        name: "•Lost baggage",
        des: "Covers the cost of lost clothes, documents, and jewellery "
      },
      {
        name: "•Trip cancellation",
        des: "Reimburses non-refundable costs like flights, accommodation, and tours if you're forced to cancel."
      },
      {
        name: "•Passport loss",
        des: "Covers the cost of replacing a lost passport."
      }
    ],
    image: health04,
  },

  {
    id: "05",
    title: "Health Top Up Insurance",
    description:
      "A health top-up medical insurance plan is a supplemental policy that provides additional coverage beyond the limits of a primary health insurance policy. It's a cost-effective way to increase coverage without significantly raising premiums.",
    imgFirst: true,
    image: health05,
  },

];

const OurHealthInsurancePage = () => {
  return (
    <div className="flex flex-col gap-[50px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Our Health Insurance"
        description="Health Insurance That Cares for You.
Reliable Protection for Your Well-being and Peace of Mind."
      />
      <div className="flex flex-col gap-[100px]">
        {insuranceData.map((insurance) => (
          <InsuranceCard
            key={insurance.id}
            title={insurance.title}
            description={insurance.description}
            image={insurance.image}
            imgFirst={insurance.imgFirst}
            id={insurance.id}
            cover={insurance.cover}
          />
        ))}
      </div>
    </div>
  );
};

export { OurHealthInsurancePage };
