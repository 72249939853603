import React, { useState } from "react";

const ButtonGroup = ({ buttons, onSelect }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (buttonName) => {
    setActiveIndex(buttons.indexOf(buttonName));
    onSelect && onSelect(buttonName);
  };

  return (
    <div className="flex border border-oliveGreen rounded-full overflow-hidden p-[6px] w-[fit-content]">
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={() => handleClick(button)}
          className={`sm:px-6 sm:py-2 px-3 py-1 text-[10px] sm:text-sm font-medium transition-colors rounded-[67px] ${
            button === buttons[activeIndex]
              ? "bg-custom-gradient text-white"
              : "text-oliveGreen hover:bg-[#c2ed87]"
          }`}
        >
          {button}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
