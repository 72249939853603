import React from "react";
import healthImg from "../../assets/healthImg.png";

const AboutHealthInsurancePage = ({contactUsRef}) => {

  const scrollToContactUS = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  
  return (
    <div className="flex gap-[50px] lg:mx-[130px] mx-[20px] md:flex-row flex-col">
      <div className="flex flex-col justify-center">
        <div className="flex gap-[6px] items-center">
          <p className="text-oliveGreen text-24 m-0 font-normal">
            Health Insurance
          </p>
          <div className="bg-oliveGreen w-[99px] h-[1px]"></div>
        </div>
        <p className="text-black text-[25px] sm:text-[48px] m-0 font-semibold leading-[37px] sm:leading-[57px]">
          Know More About Our Health Insurance
        </p>
        <p className="text-black text-[14px] mt-[24px] font-normal leading-[21px]">
          Health insurance includes various plans to cover medical expenses,
          with a family health insurance plan offering coverage for your entire
          family under a single premium. The sum assured is shared across all
          members, typically covering up to six people. There are two types of
          plans: individual health insurance policies, where each member is
          covered separately, and family floater policies, which cover the whole
          family under one plan, making it more affordable and easier to manage.
          In addition, Critical Illness Insurance provides a lump-sum payout
          upon diagnosis of serious conditions like cancer, heart disease, or
          stroke, helping with treatment costs. Personal Accident Insurance
          offers financial protection for accident-related injuries or death,
          paying a lump sum to cover medical bills and lost income. It can be
          purchased as an individual or group plan to cover multiple members.
        </p>

        <button onClick={() => scrollToContactUS(contactUsRef)} className="text-white bg-[#688540] w-[fit-content] h-[40px] mt-[40px] rounded-full py-2 px-4 text-xs font-semibold hover:bg-[#557032]">
          Free Insurance Consultancy
        </button>
      </div>

      <img
        src={healthImg}
        alt=""
        className="w-[500px] h-[629px] object-cover rounded-[20px] 320px-767px:w-full 320px-767px:h-[400px] 768px-1022px:w-[300px] 768px-1022px:h-[300px]"
      />
    </div>
  );
};

export { AboutHealthInsurancePage };
