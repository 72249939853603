import React, { useState } from "react";
import TextWithBorders from "../components/componentHeading";
import ButtonGroup from "../components/ButtonGroup";
import motor1Img from "../assets/motor1Img.png";
import motor2Img from "../assets/motor2Img.jpg";
import motor3Img from "../assets/motor3Img.jpg";
import health01 from "../assets/health01.png";
import health02 from "../assets/health02.png";
import health03 from "../assets/health03.png";
import life01 from "../assets/life01.png";
import life02 from "../assets/life02.png";
import life03 from "../assets/life03.png";
import Miscellaneous07 from "../assets/Miscellaneous07.jpg";
import Miscellaneous05 from "../assets/Miscellaneous05.jpg";
import Miscellaneous01 from "../assets/Miscellaneous01.jpg";

import {
  ComprehensiveIcon,
  MotorThirdPartyIcon,
  OwnDamageIcon,
} from "../utils/SVG";
import { useNavigate } from "react-router-dom";

const TypesOfInsurancePage = () => {
  const [selectedButton, setSelectedButton] = useState("Motor");
  const navigate = useNavigate();

  const cardData = {
    Motor: [
      {
        imgSrc: motor1Img,
        icon: <MotorThirdPartyIcon />,
        title: "Third Party",
        link: "/motor",
        description:
          "Third-party car and bike insurance is a legally mandated policy that protects vehicle owners from financial and legal liabilities arising from accidents involving their vehicles. ",
      },
      {
        imgSrc: motor2Img,
        icon: <ComprehensiveIcon />,
        title: "Comprehensive",
        link: "/motor",
        description:
          "As the name implies, a comprehensive car and bike insurance policy offers extensive coverage, providing all-around protection for your vehicle. It includes the mandatory third-party liability cover while also covering damages to your own vehicle.",
      },
      {
        imgSrc: motor3Img,
        icon: <OwnDamageIcon />,
        title: "Own damage",
        link: "/motor",
        description:
          "If you want coverage specifically for damages to your own vehicle, you need to opt for an own damage (OD) insurance plan. This type of insurance covers losses or damages to your vehicle caused by accidents, theft, fire, or natural and man-made disasters.",
      },
    ],
    Health: [
      {
        imgSrc: health01,
        icon: <MotorThirdPartyIcon />,
        title: "Critical Illness Insurance",
        link: "/health",
        description:
          "Critical illness insurance provides financial protection against life-threatening health conditions such as cancer, kidney failure, stroke, paralysis, and organ transplants. These illnesses often require costly, long-term treatments that can strain finances",
      },
      {
        imgSrc: health02,
        icon: <ComprehensiveIcon />,
        title: "Personal Accident Insurance",
        link: "/health",
        description:
          "Personal accident insurance is a type of coverage that provides financial protection in case of accidents, covering contingencies like injury, disability, or death. The policy offers a lump-sum payout to help cover medical costs, loss of income, or other expenses arising from the accident. ",
      },
      {
        imgSrc: health03,
        icon: <OwnDamageIcon />,
        title: "Family Health Insurance",
        link: "/health",
        description:
          "A **family health insurance plan** is a policy that provides health coverage for your entire family, including your spouse, children, dependent siblings, parents, and parents-in-law. With a single premium payment, the sum assured is shared among all covered members, typically extending coverage to up to six individuals.",
      },
    ],
    Life: [
      {
        imgSrc: life01,
        icon: <MotorThirdPartyIcon />,
        title: "Term Insurance",
        link: "/life",
        description:
          "Term insurance provides financial protection for the policyholder's family in the event of death during the policy term. The policy is cost-effective, with premiums based on age, health, and coverage amount. ",
      },
      {
        imgSrc: life02,
        icon: <ComprehensiveIcon />,
        title: "Pension Plans",
        link: "/life",
        description:
          "Pension plans are designed to provide income after retirement, with options such as deferred and immediate annuities. Deferred annuities begin after a set period, while immediate annuities start payments right away. ",
      },
      {
        imgSrc: life03,
        icon: <OwnDamageIcon />,
        title: "Child investment plans",
        link: "/life",
        description:
          "Child investment plans are specialized insurance policies designed to secure a child’s future by covering educational expenses and other significant financial needs.",
      },
    ],
    Miscellaneous: [
      {
        imgSrc: Miscellaneous01,
        icon: <MotorThirdPartyIcon />,
        title: "Fire Insurance",
        link: "/miscellaneous",
        description:
          "Fire insurance provides financial protection against damages or losses caused by fire and related perils such as lightning or explosion. This policy covers the cost of repairing, rebuilding, or replacing insured property, including buildings, machinery, and contents. ",
      },
      {
        imgSrc: Miscellaneous07,
        icon: <ComprehensiveIcon />,
        title: "Home Insurance",
        link: "/miscellaneous",
        description:
          "Home insurance provides financial protection for homeowners against damages or losses to their house and personal belongings due to risks like fire, theft, natural disasters, or vandalism. ",
      },
      {
        imgSrc: Miscellaneous05,
        icon: <OwnDamageIcon />,
        title: "Business Insurance",
        link: "/miscellaneous",
        description:
          "Business insurance is a comprehensive policy designed to protect companies from financial losses due to unforeseen events. It includes coverage for property damage, liability claims, employee-related risks, and business interruptions. ",
      },
    ],
  };

  const handleButtonSelect = (buttonName) => {
    console.log("Selected button name", buttonName);
    setSelectedButton(buttonName);
  };

  const renderCards = (cards) => {
    return cards.map((card, index) => (
      <div
        key={index}
        onClick={() => navigate(card?.link)}
        className="bg-white cursor-pointer shadow-custom-shadow rounded-20"
      >
        <div className="relative">
          <img
            src={card?.imgSrc}
            alt={card?.title}
            className="rounded-[10px] w-full h-[247px]"
          />
          <div className="bg-white absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 w-[89px] h-[89px] rounded-full flex items-center justify-center">
            <div className="bg-custom-gradient w-[80px] h-[80px] rounded-full flex items-center justify-center">
              {card?.icon}
            </div>
          </div>
        </div>
        <div className="px-[16px] pt-[29px] pb-[40px] flex flex-col gap-[10px] mt-[20px]">
          <p className="text-center text-[30px] font-semibold leading-[32px]">
            {card?.title}
          </p>
          <p className="text-center text-14 font-normal leading-[24px]">{card?.description}</p>
        </div>
      </div>
    ));
  };

  return (
    <div className="flex flex-col items-center gap-[30px] pt-[10px] md:px-[80px] px-[30px]">
      <TextWithBorders
        title="Our Insurance"
        subtitle="Types Of Insurance Products"
        description="Explore a Wide Range of Insurance Solutions.
Tailored Coverage to Meet Your Unique Needs."
      />

      <ButtonGroup
        buttons={["Motor", "Health", "Life", "Miscellaneous"]}
        onSelect={handleButtonSelect}
      />

      <div className="grid grid-cols-3 320px-767px:grid-cols-1 768px-1022px:grid-cols-2 gap-[32px]">
        {renderCards(cardData[selectedButton])}
      </div>
    </div>
  );
};

export { TypesOfInsurancePage };
