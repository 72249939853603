import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { ButtonComp } from "../components/ButtonComponent";

const FreeConsultancyFormPage = () => {
  return (
    <Form
      layout="vertical"
      className="w-full md:w-[700px] !bg-[#FFFFFF] md:p-[30px] p-[20px] rounded-[20px]"
      style={{ boxShadow: "0px 4px 62.5px 0px #00000040" }}
    >
      <p className="text-[24px] smtext-[40px] text-center font-normal text-black leading-[32px] sm:leading-[48px] px-[50px]">
        We have the solution for you! Take a Free Consultancy
      </p>
      <div className="flex justify-between w-full gap-[16px] mt-[36px] md:flex-row flex-col">
        <Form.Item
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
          label="Name"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input prefixCls="form-input" placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          style={{
            width: "100%",
            marginBottom: "16px",
          }}
          label="Your Age"
          name="age"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input prefixCls="form-input" placeholder="Enter your age" />
        </Form.Item>
      </div>

      <Form.Item
        label="Email Address"
        style={{
          width: "100%",
          marginBottom: "16px",
        }}
        name="email"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input prefixCls="form-input" placeholder="Enter Email Address" />
      </Form.Item>

      <Form.Item
        label="Phone Number"
        style={{
          width: "100%",
          marginBottom: "16px",
        }}
        name="phoneNumber"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input prefixCls="form-input" placeholder="Enter Phone Number" />
      </Form.Item>
      <Form.Item
        label="Description"
        style={{
          width: "100%",
          marginBottom: "16px",
        }}
        name="description"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea
          placeholder="Write a text here..."
          autoSize={{
            minRows: 5,
            maxRows: 5,
          }}
        />
      </Form.Item>

      <ButtonComp
        textcard="Submit"
        color={"white"}
        background={"#688540"}
        height="50px"
        borderRadius="10px"
        handleAction={() => console.log("click")}
      />
    </Form>
  );
};

export { FreeConsultancyFormPage };
