import React from "react";
import TextWithBorders from "../../components/componentHeading";
import InsuranceCard from "../../components/InsuranceDetails";

import moter01 from "../../assets/moter01.png";
import moter02 from "../../assets/moter02.png";
import moter03 from "../../assets/moter03.png";

const insuranceData = [
  {
    id: "01",
    title: "Third Party",
    description:
      "Third-party car and bike insurance is a legally mandated policy that protects vehicle owners from financial and legal liabilities arising from accidents involving their vehicles. It covers damages caused to a third party, including bodily injuries, death, or property damage. This type of insurance ensures that in the event of an unfortunate incident, the policyholder does not have to bear the financial burden of compensating the affected party, as the insurer steps in to cover these costs.While third-party insurance primarily focuses on safeguarding others affected by your vehicle, it also provides peace of mind by ensuring compliance with legal requirements under the Motor Vehicles Act. However, it does not cover damages to your own vehicle. For enhanced protection, vehicle owners can consider comprehensive insurance plans that include both third-party coverage and own-damage protection, offering broader financial security in case of accidents or unforeseen events.",
    imgFirst: true,
    image: moter01,
  },
  {
    id: "02",
    title: "Comprehensive",
    description:
      "As the name implies, a comprehensive car and bike insurance policy offers extensive coverage, providing all-around protection for your vehicle. It includes the mandatory third-party liability cover while also covering damages to your own vehicle. Whether your bike or car suffers damage due to accidents, theft, fire, or natural disasters, a comprehensive policy ensures financial security and peace of mind.Unlike a third-party insurance policy, which only covers damages caused to others, a comprehensive policy protects you against both third-party liabilities and own damages. It also provides coverage for unforeseen events such as vandalism, floods, and fire, making it a well-rounded solution for safeguarding your vehicle. With its broader scope, a comprehensive insurance policy is the ideal choice for complete protection and enhanced security.",
    imgFirst: false,
    image: moter02,
  },
  {
    id: "03",
    title: "Own Damage",
    description:
      "If you want coverage specifically for damages to your own vehicle, you need to opt for an own damage (OD) insurance plan. This type of insurance covers losses or damages to your vehicle caused by accidents, theft, fire, or natural and man-made disasters. While own damage coverage is typically included in comprehensive insurance policies, it is also available as a standalone OD policy for added flexibility.A standalone own damage insurance plan focuses solely on protecting your vehicle and does not cover third-party liabilities. It is issued for a one-year term and can only be purchased if you already have a valid third-party liability policy. This ensures compliance with legal requirements while giving policyholders the option to enhance protection for their own vehicle without opting for a full comprehensive plan.",
    imgFirst: true,
    image: moter03,
  },
];

const OurMoterInsurancePage = () => {
  return (
    <div className="flex flex-col gap-[50px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Our Motor Insurances"
        description="Comprehensive Coverage for Your Vehicle’s Protection.
Drive with Confidence and Peace of Mind."
      />
      <div className="flex flex-col gap-[100px]">
        {insuranceData.map((insurance) => (
          <InsuranceCard
            key={insurance.id}
            title={insurance.title}
            description={insurance.description}
            image={insurance.image}
            imgFirst={insurance.imgFirst}
            id={insurance.id}
          />
        ))}
      </div>
    </div>
  );
};

export { OurMoterInsurancePage };
