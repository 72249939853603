import React from "react";

const InsuranceCard = ({ title, description, image, imgFirst, id, cover }) => {
  return (
    <div
      className={`flex gap-[50px] items-center lg:mx-[110px] mx-[20px] md:flex-row flex-col ${!imgFirst ? "flex-row-reverse" : ""}`}
    >
      <img
        src={image}
        alt={title}
        className="w-[500px] h-[491px] 320px-767px:w-full 320px-767px:h-[400px] 768px-1022px:w-[300px] 768px-1022px:h-[300px] object-cover rounded-[20px]"
      />
      <div className="flex flex-col justify-center">
        <p className="text-black text-[48px] font-semibold ">{id}</p>
        <p className="text-black text-[32px] font-semibold ">{title}</p>
        <p className="text-black text-[14px] mt-[24px] font-normal leading-[21px]">
          {description}
        </p>
        {cover && cover?.length > 0 &&
          <div>

            <p className="text-black text-[28px] mt-[12px] font-semibold " >
              Cover
            </p>

            {cover?.map((el) => (
              <p className="text-black text-[14px] mt-[4px] font-normal leading-[21px]">
                <span className="font-semibold" >{el?.name}: </span>
                {el?.des}
              </p>

            ))}

          </div>

        }
      </div>
    </div>
  );
};

export default InsuranceCard;
