import React from 'react'
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
} from "react-router-dom";
import { PublicPaths } from './PublicPaths';
import Layout from '../layout';

export default function AkRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />} >
                    {PublicPaths?.map((routes, index) => (
                        <Route
                            key={index}
                            path={routes.path}
                            element={routes.component}
                        />
                    ))}
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </BrowserRouter>
    )
}
