import React from "react";
import motorImg from "../../assets/moterImg.png";

const AboutMoterInsurancePage = ({contactUsRef}) => {

  const scrollToContactUS = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className="flex gap-[50px] lg:mx-[130px] mx-[20px] md:flex-row flex-col">
      <div className="flex flex-col justify-center">
        <div className="flex gap-[6px] items-center">
          <p className="text-oliveGreen text-24 m-0 font-normal">Moter Insurance</p>
          <div className="bg-oliveGreen w-[99px] h-[1px]"></div>
        </div>
        <p className="text-black text-[25px] sm:text-[48px] m-0 font-semibold leading-[37px] sm:leading-[57px]">
          Know More About Our Moter Insurance
        </p>
        <p className="text-black text-[14px] mt-[24px] font-normal leading-[21px]">
          Motor insurance offers financial protection against damages,
          accidents, and liabilities involving your vehicle. It includes
          third-party insurance, which covers legal liabilities for injuries,
          death, or property damage caused to others, and own damage (OD)
          insurance, which protects your vehicle against theft, fire, accidents,
          and natural or man-made disasters. You can choose a comprehensive
          policy for all-around protection, combining third-party liability and
          own damage coverage, or opt for a standalone OD policy alongside a
          mandatory third-party plan for flexibility and compliance.
        </p>

        <button onClick={() => scrollToContactUS(contactUsRef)}  className="text-white bg-[#688540] w-[fit-content] h-[40px] mt-[40px] rounded-full py-2 px-4 text-xs font-semibold hover:bg-[#557032]">
          Free Insurance Consultancy
        </button>
      </div>

      <img
        src={motorImg}
        alt=""
        className="w-[500px] h-[629px] object-cover rounded-[20px] 320px-767px:w-full 320px-767px:h-[400px] 768px-1022px:w-[300px] 768px-1022px:h-[300px]"
      />
    </div>
  );
};

export { AboutMoterInsurancePage };
