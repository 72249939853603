import React from "react";
import formBgImg from "../assets/formBgImg.jpg";
import { FreeConsultancyFormPage } from "./FreeConsultancyForm";

const FreeConsultancyPage = ({ contactUsRef }) => {
  return (
    <div
      ref={contactUsRef}
      className="relative w-full bg-cover bg-center flex justify-center mt-50"
      style={{
        backgroundImage: `linear-gradient(to bottom, transparent, #334E25), url(${formBgImg})`,
      }}
    >
      <div className="relative z-2 pointer-events-auto mt-[100px]">
        <FreeConsultancyFormPage />
      </div>
    </div>
  );
};

export { FreeConsultancyPage };
