import React from "react";
import CoverPhoto from "../../components/CoverPhoto";
import AchievementPhoto from "../../assets/achievement.jpg";
import { ProudMomentPage } from "./ProudMoment";
import { AchivementImgsPage } from "./AchivementImgs";
import { useOutletContext } from "react-router-dom";

function Achievement() {
  const { contactUsRef } = useOutletContext();

  return (
    <div className="w-full h-full flex flex-col gap-100">
      <CoverPhoto title="Our Achievement" image={AchievementPhoto} />
      <ProudMomentPage contactUsRef={contactUsRef} />
      <AchivementImgsPage/>
    </div>
  );
}

export { Achievement };
