import "./App.css";
import AkRoutes from "./routes/AkRoutes";

function App() {
  // test comment
  return (
    <div>
      <AkRoutes />
    </div>
  );
}

export default App;
