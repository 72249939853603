import React, { useRef } from "react";
import { HeaderFile, LoginToYourAccount } from "../modules";
import { Outlet } from "react-router-dom";
import FooterPage from "../components/Footer";

const Layout = () => {
  const contactUsRef = useRef(null);
  return (
    <div className="relative flex flex-col h-full">
      <LoginToYourAccount />
      <div className="lg:py-[26px] lg:px-[50px] py-[0px] px-[0px] bg-white sticky top-[-1px] z-10">
        <HeaderFile contactUsRef={contactUsRef} />
      </div>

      <div>
        <Outlet context={{ contactUsRef }} />
      </div>

      <FooterPage contactUsRef={contactUsRef} />
    </div>
  );
};

export default Layout;
