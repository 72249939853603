import { Navigate } from "react-router-dom";
import { HomePage, About, Moter, Life, Health, Miscellaneous, Achievement } from "../modules";
import PhotoGallery from "../test/PhotoGallery";



export const PublicPaths = [
    { path: "/", component: <Navigate to="/home" replace /> },
    {
        path: "/home",
        component: <HomePage />,
    },
    {
        path: "/about",
        component: <About />,
    },
    {
        path: "/motor",
        component: <Moter />,
    },
    {
        path: "/life",
        component: <Life />,
    },
    {
        path: "/health",
        component: <Health />,
    },
    {
        path: "/miscellaneous",
        component: <Miscellaneous />,
    },
    {
        path: "/achievement",
        component: <Achievement />,
    },
    {
        path: "/test-gallery",
        component: <PhotoGallery />,
    },

];
