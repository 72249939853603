import React from "react";
import CoverPhoto from "../../components/CoverPhoto";
import MiscellaneousPhoto from "../../assets/miscellaneous.jpg";
import { AboutMiscellaneousInsurancePage } from "./AboutMiscellaneousInsurance";
import { OurMiscellaneousInsurancePage } from "./OurMiscellaneousInsurance";
import { TestimonialPage } from "../Testimonial";
import { useOutletContext } from "react-router-dom";

function Miscellaneous() {
  const { contactUsRef } = useOutletContext();

  return (
    <div className="w-full h-full flex flex-col gap-100">
      <CoverPhoto title="Miscellaneous Insurance" image={MiscellaneousPhoto} />
      <AboutMiscellaneousInsurancePage contactUsRef={contactUsRef} />
      <OurMiscellaneousInsurancePage />
      <TestimonialPage />
    </div>
  );
}

export { Miscellaneous };
