import React from "react";
import mobileImg from "../assets/mobileImg.png";
import maskImg from "../assets/Mask.png";
import { ButtonComp } from "../components/ButtonComponent";
import { useOutletContext } from "react-router-dom";
const ManageYourInsurancePage = () => {
  const { contactUsRef } = useOutletContext();

  const scrollToContactUS = (ref) => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className="bg-custom-gradient w-full h-full rounded-[20px] flex justify-between relative mt-[30px] md:mt-[84px] px-[30px]">
      <div className="absolute inset-0 pointer-events-none">
        <img width={'100%'} src={maskImg} alt="" />
      </div>
      {/* left */}
      <div className="flex flex-col gap-[32px] md:p-[60px] py-[10px]">
        <div>
          <div className="flex gap-[6px] items-center">
            <p className="text-white text-24 m-0 font-normal">Mobile web app</p>
            <div className="bg-white w-[99px] h-[1px]"></div>
          </div>

          <p className="text-white 320px-767px:text-[38px] text-64 m-0 font-semibold 320px-767px:w-full w-[564px] 320px-767px:leading-[47px] leading-[76.8px] mt-[11px] mb-[16px]">
            Manage Your Insurance Anytime, Anywhere
          </p>
          <p className="text-white text-14 m-0 font-normal 320px-767px:w-full w-[489px] leading-[21px]">
            Stay in control of your insurance with our mobile web app. Access
            all your policy details, make updates, and track claims—right from
            your smartphone. Designed for convenience, our platform gives you
            the freedom to manage your insurance whenever and wherever you need.
          </p>
        </div>
        <div className="relative  pointer-events-auto">
          <ButtonComp
            textcard="Get Started"
            color={"black"}
            background={"#E7C26E"}
            width="175px"
            height="40px"
            borderRadius="100px"
            handleAction={() => window.open("https://user.akinvestments.co.in/", "_blank")}
          />
        </div>
        <div className="xl:hidden block">
          <img src={mobileImg} alt="" />
        </div>
      </div>

      {/* right */}
      <div className="absolute right-[5%] top-[-12%] xl:block hidden">
        <img src={mobileImg} alt="" />
      </div>
    </div>
  );
};

export { ManageYourInsurancePage };
