import React from "react";
import { MdOutlineMail } from "react-icons/md";

const LoginToYourAccount = () => {
  return (
    <div className="flex bg-oliveGreen justify-between lg:px-70 px-4 h-50 gap-5 items-center">
      <div className="flex gap-5 items-center">
        {/* Phone Section */}
        <div className="flex items-center gap-2">
          <p className=" text-white text-[10px] sm:text-14 m-0 font-normal">
            Phone no: +91 8447118742
          </p>
        </div>

        <div className="bg-[#D9D9D9] w-[1px] h-[30px]"></div>

        {/* Email Section */}
        <div className="flex items-center gap-2">
          <MdOutlineMail className="block lg:hidden text-white" />
          <p className="hidden lg:block text-white text-14 m-0 font-normal">
            <a href="mailto:willie.jennings@example.com">
              Email: info@akinvestments.co.in
            </a>
          </p>
        </div>
      </div>

      {/* Login Button */}
      <div
        className="text-black text-14 font-medium bg-customGold hover:bg-hoverGold h-50 cursor-pointer  w-211 flex items-center justify-center "
        onClick={() =>
          window.open("https://user.akinvestments.co.in/", "_blank")
        }
      >
        Login to your account
      </div>
    </div>
  );
};

export { LoginToYourAccount };
