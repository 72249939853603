import React from "react";

function CoverPhoto({ title, image }) {
    return (
        <div className="w-full h-full lg:px-[30px] pt-[14px] p-4">
            <div
                className="relative rounded-[20px] bg-center bg-cover"
                style={{
                    backgroundImage: `url(${image})`,
                }}
            >
                <div className="absolute inset-0 bg-[#334E2599] rounded-[20px]"></div>

                <div className="w-full h-[433px] relative text-white flex flex-col items-center justify-center">
                    <div className="flex items-center gap-[6px]">
                        <div className="bg-white h-[1px] w-[50px]"></div>
                        <p className="text-center text-[24px] font-normal">
                            a.k. investments
                        </p>
                        <div className="bg-white h-[1px] w-[50px]"></div>
                    </div>

                    <h1 className="sm:text-[76px] text-[38px] md:text-start text-center font-semibold">{title}</h1>
                </div>
            </div>
        </div>
    );
}

export default CoverPhoto;
