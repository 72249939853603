import React, { useCallback } from "react";
import {
  HappyCustomerIcon,
  InsurancePartnerIcon,
  TrailedAdvisorIcon,
} from "../utils/SVG";
import TextWithBorders from "../components/componentHeading";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const WhyChooseAKInvestment = () => {
  const { ref, inView } = useInView({
    threshold: 0.6, // Trigger animation when 20% of the section is visible
    triggerOnce: false, // Ensure animation runs only once
  });

  const items = [
    {
      Icon: TrailedAdvisorIcon,
      count: 50,
      label: "Trailed Advisors",
      bgColor: "bg-yellowGold",
    },
    {
      Icon: HappyCustomerIcon,
      count: 20000,
      label: "Happy Customers",
      bgColor: "bg-brightGreen",
    },
    {
      Icon: InsurancePartnerIcon,
      count: 45000,
      label: "Insurance Partners",
      bgColor: "bg-brightOrange",
    },
  ];

  const RunningCountUp = useCallback(({ count }) => {
    if (inView) {
      return (
        <CountUp end={count} suffix="+" />
      )
    }
  }, [inView]);

  return (
    <div ref={ref} className="flex flex-col gap-[32px] lg:px-[130px] px-[20px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Why Choose Us"
        description="Discover What Sets Us Apart.
Your Trusted Partner for Exceptional Insurance Solutions."
      />

      <div className="flex lg:justify-between sm:gap-[0px] gap-[20px] sm:flex-row flex-col items-center justify-around">
        {items?.map(({ Icon, count, label, bgColor }, index) => (
          <div className="flex gap-[20px] lg:flex-row flex-col " key={index}>
            <div
              className={`${bgColor} w-[129px] h-[129px] rounded-[20px] flex justify-center items-center`}
            >
              <Icon />
            </div>
            <div className="flex flex-col justify-center gap-10">
              <p className="m-0 text-darkGreen text-40 font-extrabold leading-[40px] text-left">
                <RunningCountUp count={count} />
              </p>
              <p className="m-0 text-mediumGreen text-16 font-normal leading-[22.4px] text-left">
                {label}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { WhyChooseAKInvestment };
