import React, { useEffect } from "react";
import Logo from "../assets/logo.png";
import { FreeConsultancyPage } from "../modules";
import { Divider } from "antd";
import { FacebookIcon, InstagraminIcon, LinkedinIcon } from "../utils/SVG";
import { useLocation, useNavigate } from "react-router-dom";

const FooterPage = ({ contactUsRef }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToContactUS = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <div className="w-full h-full mt-[100px]">
      {location?.pathname !== "/about" && (
        <FreeConsultancyPage contactUsRef={contactUsRef} />
      )}
      <div className="w-full h-full bg-[#334E25]">
        <div className="bg-darkGreen lg:px-[70px] p-4 lg:pt-[49px] lg:pb-[40px] flex flex-col gap-[40px]">
          {/* top */}
          <div className="grid grid-cols-2 gap-[30px] md:flex md:justify-between">
            <div className="flex flex-col gap-[10px] lg:gap-[21px]">
              <img src={Logo} alt="Logo" className="w-[197px] h-[50px]" />
              <p className="text-[10px] w-[150px] lg:text-[16px] font-normal text-white sm:w-[295px] leading-[22px]">
                Providing best insurance solutions for you & your family
                security
              </p>
              <p onClick={() => navigate("/about")} className="text-[10px] cursor-pointer lg:text-[14px] font-normal text-white mt-[3px]">
                Know More <span>→</span>
              </p>
            </div>

            <div className="flex flex-col gap-[20px]  lg:gap-[40px]">
              <p className="text-[16px] lg:text-[20px] font-bold text-white">
                Site map
              </p>
              <div className="flex flex-col gap-[10px]  lg:gap-[16px]">
                <p
                  onClick={() => navigate("/home")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  Home
                </p>
                <p
                  onClick={() => navigate("/about")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  About
                </p>
                <p
                  onClick={() => navigate("/achievement")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  Achievement
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-[20px] lg:gap-[40px]">
              <p className="text-[16px] lg:text-[20px] font-bold text-white">
                Insurance
              </p>
              <div className="flex flex-col gap-[10px] lg:gap-[16px]">
                <p
                  onClick={() => navigate("/motor")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  Motor
                </p>
                <p
                  onClick={() => navigate("/life")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  Life
                </p>
                <p
                  onClick={() => navigate("/health")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  Health
                </p>
                <p
                  onClick={() => navigate("/miscellaneous")}
                  className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white"
                >
                  Miscellaneous
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-[20px] lg:gap-[40px]">
              <p className="text-[16px] lg:text-[20px] font-bold text-white">
                Info Links
              </p>
              <div className="flex flex-col gap-[10px] lg:gap-[16px]">
                <p onClick={() => window.open('https://user.akinvestments.co.in/', "_blank")} className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white">
                  Login to your account
                </p>
                <p onClick={() => scrollToContactUS(contactUsRef)} className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white">
                  Free Insurance Consultancy
                </p>
                <p onClick={() => scrollToContactUS(contactUsRef)} className="text-[14px] cursor-pointer lg:text-[18px] font-normal text-white">
                  Free Doctor Consultancy
                </p>
              </div>
            </div>
          </div>

          <Divider
            style={{
              backgroundColor: "#D9D9D9",
              height: "1px",
              marginTop: "0px",
            }}
          />
          {/* bottom */}

          <div className="flex-col-reverse sm:flex-row flex justify-between gap-[15px] sm:gap-[0px] items-start sm:items-center max-380px:items-center">
            <div className="flex gap-[20px] max-380px:mr-[40px]">
              <div className="lg:w-[55px] lg:h-[55px] w-[40px] h-[40px] border border-white rounded-[18px] flex items-center justify-center bg-transparent hover:bg-white group">
                <FacebookIcon />
              </div>
              <div className="lg:w-[55px] lg:h-[55px] w-[40px] h-[40px] border border-white rounded-[18px] flex items-center justify-center bg-transparent hover:bg-white group">
                <LinkedinIcon />
              </div>
              <div className="lg:w-[55px] lg:h-[55px] w-[40px] h-[40px] border border-white rounded-[18px] flex items-center justify-center bg-transparent hover:bg-white group">
                <InstagraminIcon />
              </div>
            </div>

            <div className="flex gap-[70px] max-460px:gap-[10px] max-380px:flex-col">
              <div className="flex flex-col gap-[16px]">
                <p className="text-[20px] lg:text-[24px] font-[500] text-white">
                  Location
                </p>
                <p className="text-[12px] lg:text-[14px] font-normal text-white leading-[21px] w-[200px]">
                  401, SG Alpha Tower-II, Sector-9, Vasundhara, Ghaziabad- 201012 (Delhi NCR)
                </p>
              </div>

              <div className="flex flex-col gap-[16px]">
                <p className="text-[20px] lg:text-[24px] font-[500] text-white">
                  Contact Us
                </p>
                <p className="text-[12px] lg:text-[14px] font-normal text-white">
                  +91 8447118742
                </p>
                <p className="text-[12px] lg:text-[14px] font-normal text-white">
                  <a
                    href="mailto:info@akinvestments.co.in"
                  >
                    info@akinvestments.co.in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterPage;
