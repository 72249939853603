import React from "react";
import aboutBgImg from "../../assets/aboutBgImg.jpg";
import ceo from "../../assets/ceo.jpg";

const AboutDirectorPage = () => {
  return (
    <div
      className="bg-cover bg-center bg-custom-gradient-overlay"
      style={{
        backgroundImage: `
          linear-gradient(0deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.5)),
          linear-gradient(118.15deg, rgba(0, 0, 0, 0) 53.08%, rgba(0, 0, 0, 0.7) 80.79%),
          url(${aboutBgImg})
        `,
      }}
    >
      <div className="flex justify-center h-full space-y-8 lg:m-[90px] m-[40px] gap-[50px] max-900px:flex-col">
        <img
          src={ceo}
          alt="Director"
          className="w-[413px] h-[589px] object-cover rounded-[20px] max-900px:w-full max-900px:h-[400px]"
        />

        <div className="h-[550px] !m-auto max-460px:h-full">
          <div className="flex gap-[6px] items-center">
            <p className="text-white text-24 m-0 font-normal">About Director</p>
            <div className="bg-white w-[99px] h-[1px]"></div>
          </div>
          <p className="text-white text-[48px] m-0 font-semibold">
            Dr. Amit Kumar Chaudhary
          </p>
          <p className="text-white text-[14px] m-0 font-normal leading-[21px]">
            Welcome to A.K. Investments, where excellence meets integrity. At the helm of our organization is Dr. Amit Kumar Chaudhary, a visionary leader with a passion for safeguarding lives, health, and assets.
          </p>
          <p className="text-white text-[14px] mt-[4px] font-normal leading-[21px]">
            A medical graduate by training, Dr. Amit kumar Chaudhary began his professional journey with ICICI Lombard, where he served for five impactful years. During his tenure, he uncovered multiple frauds, saving millions for the company and revolutionizing fraud prevention strategies across the Insurance industry.
          </p>

          <p className="text-white text-[14px] mt-[4px] font-normal leading-[21px]">
            In 2013, driven by his commitment to ensuring fairness in the Insurance sector, Dr. Chaudhary established his business specializing in insurance audits. Over the years, his expertise and unwavering dedication have saved crores for various Insurance Companies, reinforcing trust and transparency in the industry. His pioneering work earned him International recognition, including the prestigious Insurance Audit Award in Germany in 2017—the first-ever accolade of its kind.
          </p>


          <p className="text-white text-[14px] mt-[4px] font-normal leading-[21px]">
            Fueled by a deep desire to serve the nation, Dr. Chaudhary expanded his mission in 2022 by launching Insurance Sales Platform. This initiative is rooted in the belief that every individual deserves comprehensive protection for their health, life, and assets. His relentless efforts and exceptional service have been recognized on global platforms, earning him the coveted MDRT (Million Dollar Round Table) awards three years in a row .
          </p>


          <p className="text-white text-[14px] mt-[4px] font-normal leading-[21px]">
            Dr. Amit Kumar Chaudhary’s journey is a testament to his commitment to the greater good. His work goes beyond business; it’s a mission to secure the future of individuals and families across the nation. At A.K. Investments, we don’t just offer financial solutions—we build trust, inspire confidence, and empower you to live with peace of mind.
          </p>

          <p className="text-white text-[32px] mt-[10px] font-normal font-pinyon">
            Dr. Amit Kumar
          </p>
        </div>
      </div>
    </div>
  );
};

export { AboutDirectorPage };
