import React, { useEffect, useState } from 'react'

export default function PhotoGallery() {
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const photos = [
        { id: 1, src: "https://images.pexels.com/photos/41257/pexels-photo-41257.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 1" },
        { id: 2, src: "https://images.pexels.com/photos/3755440/pexels-photo-3755440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 2" },
        { id: 3, src: "https://images.pexels.com/photos/7005693/pexels-photo-7005693.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 3" },
        { id: 4, src: "https://images.pexels.com/photos/6120397/pexels-photo-6120397.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 4" },
        { id: 5, src: "https://images.pexels.com/photos/3755440/pexels-photo-3755440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 5" },
        { id: 6, src: "https://images.pexels.com/photos/6120397/pexels-photo-6120397.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 6" },
        { id: 7, src: "https://images.pexels.com/photos/332835/pexels-photo-332835.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 7" },
        { id: 8, src: "https://images.pexels.com/photos/1471803/pexels-photo-1471803.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 8" },
        { id: 9, src: "https://images.pexels.com/photos/3755440/pexels-photo-3755440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 9" },
        { id: 10, src: "https://images.pexels.com/photos/6120397/pexels-photo-6120397.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1", alt: "Photo 10" },
    ]

    const renderGalleryItem = (index) => {
        if (index % 3 === 1) {
            // Render FourImageBox for every third item (1, 4, 7, ...)
            const fourPhotos = photos.slice(index, index + 4)
            console.log(fourPhotos, "fourPhotos")
            return <FourImageBox key={index} photos={fourPhotos} onImageClick={setSelectedPhoto} />
        }
        else {
            // Render single large image for other items
            const photo = photos[index]
            return <GalleryImage key={photo.id} photo={photo} onClick={() => setSelectedPhoto(photo)} />
        }
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-1 bg-black">
                {photos.map((_, index) => index < photos.length && renderGalleryItem(index))}
            </div>

            {/* <Dialog
                open={selectedPhoto !== null}
                onOpenChange={() => setSelectedPhoto(null)}
            >
                <DialogContent className="max-w-screen-lg w-full p-0 bg-black">
                    {selectedPhoto && (
                        <div className="relative w-full h-[80vh]">
                            <img
                                src={selectedPhoto.src || "/placeholder.svg"}
                                alt={selectedPhoto.alt}
                                fill
                                className="object-contain"
                                sizes="80vw"
                            />
                        </div>
                    )}
                </DialogContent>
            </Dialog> */}
        </div>
    )
}

function FourImageBox({ photos, onImageClick }) {
    return (
        <div className="grid grid-cols-2 gap-1">
            {photos?.map((photo) => (
                <GalleryImage key={photo.id} photo={photo} onClick={() => onImageClick(photo)} />
            ))}
        </div>
    )
}


function GalleryImage({ photo, onClick }) {
    const dimensions = useImageDimensions(photo.src)

    if (!dimensions) {
        return <div className="aspect-square bg-gray-200 animate-pulse" />
    }

    const aspectRatio = dimensions.height / dimensions.width

    return (
        <div
            className="relative group cursor-zoom-in overflow-hidden bg-gray-200"
            onClick={onClick}
        >
            <div className={aspectRatio > 1 ? "aspect-[3/4]" : "aspect-square"}>
                <img
                    src={photo.src || "/placeholder.svg"}
                    alt={photo.alt}
                    fill
                    className="object-cover transition-transform duration-300 group-hover:scale-105"
                    sizes="(max-width: 440px) 50vw, 25vw"
                />
            </div>
        </div>
    )
}



// HOOK 

export function useImageDimensions(src) {
    const [dimensions, setDimensions] = useState(null);

    useEffect(() => {
        const img = new Image()
        img.src = src

        const handleLoad = () => {
            setDimensions({ width: img.width, height: img.height })
        }

        img.addEventListener('load', handleLoad)

        return () => {
            img.removeEventListener('load', handleLoad)
        }
    }, [src])

    return dimensions
}