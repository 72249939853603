import React, { useState } from "react";
import TextWithBorders from "../../components/componentHeading";
import ButtonGroup from "../../components/ButtonGroup";
import { Gallery } from "react-grid-gallery";
const getRandomDimensions = () => {
  const min = 400; // minimum size
  const max = 1000; // maximum size
  return {
    width: Math.floor(Math.random() * (max - min + 1)) + min,
    height: Math.floor(Math.random() * (max - min + 1)) + min,
  };
};

const generateImages = () => {
  const baseImages = [
    "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
    "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
    "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
  ];

  const categories = ["Domestic Awards", "International Awards"];
  const images = [];

  for (let i = 0; i < 90; i++) {
    const randomBaseImage =
      baseImages[Math.floor(Math.random() * baseImages.length)];
    const randomCategory =
      categories[Math.floor(Math.random() * categories.length)];
    const { width, height } = getRandomDimensions();

    images.push({
      src: randomBaseImage,
      width,
      height,
      category: randomCategory,
    });
  }
  return images;
};

const AchivementImgsPage = () => {
  const [selectedButton, setSelectedButton] = useState("All Awards");
  const [visibleImages, setVisibleImages] = useState(15);
  const [images] = useState(generateImages());

  const filteredImages =
    selectedButton === "All Awards"
      ? images
      : images.filter((image) => image.category === selectedButton);

  const visibleFilteredImages = filteredImages.slice(0, visibleImages);

  const handleButtonSelect = (buttonName) => {
    console.log("Selected button name", buttonName);
    setSelectedButton(buttonName);
    setVisibleImages(15);
  };

  const handleLoadMore = () => {
    setVisibleImages((prev) => prev + 15);
  };

  return (
    <div className="flex flex-col justify-center items-center gap-[30px]">
      <TextWithBorders
        title="a.k. investments"
        subtitle="Achievement"
        description="Building a Legacy of Success.
Celebrating the Key Moments That Shape Our Story."
      />

      <div className="flex flex-col justify-center">
        <div className="w-full flex justify-center">
          <ButtonGroup
            buttons={["All Awards", "Domestic Awards", "International Awards"]}
            onSelect={handleButtonSelect}
          />
        </div>



        <div className="w-[90%] m-auto mt-8" >

          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005047/pexels-photo-7005047.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/2040276/pexels-photo-2040276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/6120394/pexels-photo-6120394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/6250930/pexels-photo-6250930.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005687/pexels-photo-7005687.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005693/pexels-photo-7005693.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg" alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/6827089/pexels-photo-6827089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src="https://images.pexels.com/photos/7005686/pexels-photo-7005686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
              </div>
            </div>
          </div>
        </div>




        {/* <div className="mx-[50px] max-460px:mx-[0px]">
          {visibleFilteredImages.length > 0 ? (
            <Gallery
              images={visibleFilteredImages}
              enableImageSelection={false}
            />
          ) : (
            <p className="text-center text-gray-500">No images to display</p>
          )}
        </div> */}

        {/* {visibleImages < filteredImages.length && (
          <div className="w-full flex justify-center mt-[50px]">
            <button
              onClick={handleLoadMore}
              className="text-[#688540] w-full sm:w-[336px] h-[40px] bg-white border border-[#688540] rounded-full py-2 px-4 text-xs font-semibold hover:bg-gray-100"
            >
              Load More
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export { AchivementImgsPage };
