import React from "react";
import CoverPhoto from "../../components/CoverPhoto";
import HealthPhoto from "../../assets/formBgImg.jpg";
import { AboutHealthInsurancePage } from "./AboutHealthInsurance";
import { OurHealthInsurancePage } from "./OurHealthInsurance";
import { TestimonialPage } from "../Testimonial";
import { useOutletContext } from "react-router-dom";

function Health() {
  const { contactUsRef } = useOutletContext();

  return (
    <div className="w-full h-full flex flex-col gap-100">
      <CoverPhoto title="Health Insurance" image={HealthPhoto} />
      <AboutHealthInsurancePage contactUsRef={contactUsRef} />
      <OurHealthInsurancePage />
      {/* <TestimonialPage /> */}
    </div>
  );
}

export { Health };
